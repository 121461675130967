var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('b-card',[_c('validation-observer',{ref:"createForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"createForm",attrs:{"form":_vm.$refs.createForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{attrs:{"fields":_vm.fields},scopedSlots:_vm._u([{key:"tenantFeatures",fn:function(){return [_c('div',{staticClass:"pt-2"},[_c('draggable',{attrs:{"move":_vm.onMove,"start":true,"end":true},model:{value:(_vm.tempTenantFeatures),callback:function ($$v) {_vm.tempTenantFeatures=$$v},expression:"tempTenantFeatures"}},[_c('transition-group',{attrs:{"type":"transition","name":'flip-list'}},_vm._l((_vm.tempTenantFeatures),function(feature,idx){return _c('b-col',{key:feature.id,staticClass:"p-0",attrs:{"lg":"12","md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("field.image"))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm.$t("field.optional"))+" ")])]),_c('n-async-single-image-uploader',{key:("image-upload-" + idx + "-" + _vm.featureKey),attrs:{"path":"tanant-feature","ph":"240","pw":"240","disableUploadHistory":true,"fullWidth":false,"image":feature.image,"compressMineType":_vm.image/_vm.png},on:{"change":function($event){return _vm.onUploadChange($event, idx, 'feature')}},model:{value:(feature.imageId),callback:function ($$v) {_vm.$set(feature, "imageId", $$v)},expression:"feature.imageId"}}),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (feature.nameEn)),"name":_vm.$t('field.nameEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.nameEn"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.nameEn'),"autocomplete":"off"},model:{value:(feature.nameEn),callback:function ($$v) {_vm.$set(feature, "nameEn", $$v)},expression:"feature.nameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":("" + (feature.nameKm)),"name":_vm.$t('field.nameKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("field.nameKm"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.nameKm'),"autocomplete":"off"},model:{value:(feature.nameKm),callback:function ($$v) {_vm.$set(feature, "nameKm", $$v)},expression:"feature.nameKm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"align-self":"end"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeFeature(idx)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.delete")))])],1)],1)],1),_c('hr',{staticClass:"mb-2"})],1)}),1)],1),_c('b-col',{staticClass:"p-0"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addMoreFeature}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.addFeature")))])],1)],1)],1)]},proxy:true},{key:"tenantGalleries",fn:function(){return [_c('div',{staticClass:"mt-2"},[_c('draggable',{attrs:{"move":_vm.onMove,"start":true,"end":true},model:{value:(_vm.tempTenantGalleries),callback:function ($$v) {_vm.tempTenantGalleries=$$v},expression:"tempTenantGalleries"}},[_c('transition-group',{attrs:{"type":"transition","name":'flip-list'}},_vm._l((_vm.tempTenantGalleries),function(gallery,idx){return _c('div',{key:gallery.id,staticClass:"list-group-item"},[_c('validation-provider',{attrs:{"vid":((gallery.id) + "-" + idx),"name":_vm.$t('field.image'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-async-single-image-uploader',{key:("image-upload-" + idx + "-" + _vm.key),class:errors.length > 0 ? 'is-invalid' : null,attrs:{"path":"tenant-gallery","ph":"240","pw":"240","disableUploadHistory":true,"disableSpacer":false,"fullWidth":false,"image":gallery.image},on:{"change":function($event){return _vm.onUploadChange($event, idx, 'gallery')}},model:{value:(gallery.imageId),callback:function ($$v) {_vm.$set(gallery, "imageId", $$v)},expression:"gallery.imageId"}}),_c('feather-icon',{staticClass:"close-icon",attrs:{"icon":"XIcon","size":"18"},on:{"click":function($event){return _vm.removeGallery(idx)}}})]}}],null,true)})],1)}),0)],1),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.tempTenantGalleries.length >= 1),expression:"tempTenantGalleries.length >= 1"}]}),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addMoreGallery}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.addGallery")))])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.$can('create', _vm.resource))?_c('n-button-save-option',{ref:"btnSubmit",attrs:{"loading":_vm.loading,"resource":_vm.resource,"route":_vm.route},on:{"submit":_vm.submit,"save":_vm.save}}):_vm._e(),_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary","loading":_vm.loading},on:{"submit":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }